import { CompetitionEnum, IAvailableCompetition } from "../Domain/Enums/CompetitionEnum";
import { isDev } from "../Utility/is-dev";


export interface ISettings {
	availableCompetitions: Array<IAvailableCompetition>
}

export const settings: ISettings = {
	availableCompetitions: isDev() ? [{
		Id: 1,
		Name: "Worlds Strongest Man",
		Year: 2022,
		Competition: CompetitionEnum.WorldsStrongestMan,
		DisplayOrder: 10
	}, {
		Id: 2,
		Name: "Worlds Strongest Man",
		Year: 2023,
		Competition: CompetitionEnum.WorldsStrongestMan,
		DisplayOrder: 20
	}, {
		Id: 3,
		Name: "Arnold Strongman Classic",
		Year: 2023,
		Competition: CompetitionEnum.ArnoldStrongmanClassic,
		DisplayOrder: 30
	}, {
		Id: 4,
		Name: "Worlds Strongest Man",
		Year: 2024,
		Competition: CompetitionEnum.WorldsStrongestMan,
		DisplayOrder: 40
	}, {
		Id: 5,
		Name: "Shaw Classic 2024",
		Year: 2024,
		Competition: CompetitionEnum.ArnoldStrongmanClassic,
		DisplayOrder: 50
	}, {
		Id: 6,
		Name: "Britain's Strongest Man",
		Year: 2025,
		Competition: CompetitionEnum.ArnoldStrongmanClassic,
		DisplayOrder: 60
	}, {
		Id: 7,
		Name: "Arnold Strongman Classic",
		Year: 2025,
		Competition: CompetitionEnum.ArnoldStrongmanClassic,
		DisplayOrder: 70
	}]
		:
		[{
			Id: 4,
			Name: "Worlds Strongest Man",
			Year: 2024,
			Competition: CompetitionEnum.WorldsStrongestMan,
			DisplayOrder: 10
		}, {
			Id: 5,
			Name: "Shaw Classic",
			Year: 2024,
			Competition: CompetitionEnum.ArnoldStrongmanClassic,
			DisplayOrder: 20
		}, {
			Id: 6,
			Name: "Britain's Strongest Man",
			Year: 2025,
			Competition: CompetitionEnum.ArnoldStrongmanClassic,
			DisplayOrder: 60
		}, {
			Id: 7,
			Name: "Arnold Strongman Classic",
			Year: 2025,
			Competition: CompetitionEnum.ArnoldStrongmanClassic,
			DisplayOrder: 70
		}]
};

